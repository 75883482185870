<template>
  <div class="voteDetail">
    <div class="section-warpper" v-if="detail.title">
      <div class="section-title">{{ detail.title }}</div>
      <div class="section-text">
        {{ detail.votePublicityContent || detail.content }}
      </div>
      <div class="section-picture">
        <vote-pic-list
          :picList="detail.imageUrls ? detail.imageUrls.split(',') : []"
        ></vote-pic-list>
      </div>
      <div class="section-date">
        投票期：{{ `${detail.voteStartDate}~${detail.voteEndDate}` }}
      </div>
      <div class="section-title">投票流程</div>
      <div class="section-main">
        <div class="section-main--first" v-if="firstStatus !== undefined">
          <div class="main-title">
            <div class="title-index">1</div>
            <span>认证身份，仅限小区业主及其配偶才可参与投票</span>
          </div>
          <div
            class="main-button"
            :class="firstBtnMap[firstStatus] | btnCss"
            @click="toVerified"
          >
            {{ firstBtnMap[firstStatus] }}
          </div>
        </div>
        <div class="section-main--second" v-if="secondStatus !== undefined">
          <div class="main-title">
            <div class="title-index">2</div>
            <span>身份认证成功后，参与投票</span>
          </div>
          <div
            class="main-button"
            :class="secondBtnMap[secondStatus] | btnCss"
            @click="toJoinHall"
          >
            {{ secondBtnMap[secondStatus] }}
          </div>
        </div>
        <div class="section-main--three" v-if="threeStatus !== undefined">
          <div class="main-title">
            <div class="title-index">3</div>
            <span>计票统计，查看投票结果</span>
          </div>
          <div
            class="main-button"
            :class="threeBtnMap[threeStatus] | btnCss"
            @click="toResult"
          >
            {{ threeBtnMap[threeStatus] }}
          </div>
        </div>
      </div>
    </div>
    <pdf-View
      :show="show"
      :url="detail.voteNotice"
      @check="clickCheck"
    ></pdf-View>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { mapState } from "vuex";
import { getDetailURL, checkSubmitURL, userInfoURL } from "./api";
import votePicList from "./components/picList.vue";
import pdfView from "./components/pdfView.vue";

export default {
  name: "voteDetail",
  components: {
    votePicList,
    pdfView,
  },
  data() {
    return {
      voteId: "",
      detail: {
        id: "",
        title: "",
        content: "",
        imageUrls: "",
        voteStartDate: "",
        voteEndDate: "",
        votechoice: undefined, // 0-未选择 1-赞成，2-反对，3-弃权-随多数票, 4-弃权-不随多数票
        voteResultStatus: undefined, // 投票结果状态 1: 未开始(投票时间结束前), 2: 投票结果统计中(监票时间结束前)，3:查看结果(监票时间结束)、
      },
      firstStatus: undefined,
      secondStatus: undefined,
      threeStatus: undefined,
      firstBtnMap: {
        1: "立即认证",
        2: "已认证",
      },
      secondBtnMap: {
        0: "待审核",
        1: "审核通过-未开始",
        2: "审核不通过",
        3: "未开始",
        40: "参与投票",
        41: "已投票-支持",
        42: "已投票-反对",
        43: "已投票-弃权随多数票",
        44: "已投票-弃权不随少数票",
        5: "投票已结束",
        // 1: "参与投票",
        // 2: "已投票-支持",
        // 3: "已投票-反对",
        // 4: "已投票-弃权随多数票",
        // 5: "已投票-弃权不随少数票",
        // 6: "投票已结束",
      },
      threeBtnMap: {
        1: "未开始",
        2: "投票结果统计中，业主可以去监票复核",
        3: "查看结果",
      },
      userInfo: {},
      timer: null,
      /** pdfView */
      show: false,
    };
  },
  filters: {
    btnCss(name) {
      let className = {
        立即认证: "default",
        参加大会: "default",
        查看结果: "default",
        已认证: "verified",
        待审核: "noStart",
        "审核通过-未开始": "noStart",
        未开始: "noStart",
        "已投票-支持": "vote",
        "已投票-反对": "vote",
        "已投票-弃权随多数票": "vote",
        "已投票-弃权不随少数票": "vote",
        投票已结束: "voteClose",
        审核不通过: "voteReview",
        "投票结果统计中，业主可以去监票复核": "voteReview",
      };
      return className[name] || "default";
    },
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    this.initData();
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  methods: {
    async initData() {
      const { id } = this.$route.query;
      this.voteId = id;
      await this.getuserInfo();
      await this.getDetail();
    },
    // 获取用户信息
    async getuserInfo() {
      let params = {
        userId: this.userId,
      };
      let res = await this.$axios.get(userInfoURL, { params: params });
      if (res.code === 200) {
        this.userInfo = res.data;

        if (this.userInfo.houseOwnerAuditStatus == 1) {
          // 通过houseOwnerAuditStatus判断是否业主
          this.firstStatus = 2;
        } else {
          this.firstStatus = 1;
        }
      }
    },
    async getDetail() {
      let params = {
        id: this.voteId,
        userId: this.userId,
      };
      let res = await this.$axios.get(getDetailURL, { params: params });
      if (res.code === 200) {
        this.detail = res.data;
        /* 1. secondStatus、threeStatus 赋值
         * 2. show 未投票用户弹框 - 如果投票公告有值则显示
         */
        this.setSecondStatus(this.detail);
        this.setThreeStatus(this.detail);
        if (this.detail.voteNotice) {
          this.show = true;
        }
      }
    },
    setSecondStatus(detail) {
      let result = undefined;
      if (detail.status != 4) {
        result = detail.status;
      } else {
        result = `${detail.status}${detail.votechoice}`;
      }
      this.secondStatus = result;
    },
    setThreeStatus(detail) {
      // voteResultStatus 投票结果状态 1: 未开始(投票时间结束前), 2: 投票结果统计中(监票时间结束前)，3:查看结果(监票时间结束)、
      this.threeStatus = this.detail.voteResultStatus;
    },
    // 弹框-确认按钮
    clickCheck() {
      this.show = false;
    },
    // 去认证
    toVerified() {
      if (this.firstStatus == 1) {
        let params = {
          id: this.detail.id,
        };
        if (wx.miniProgram) {
          wx.miniProgram.navigateTo({
            url: `/subPages/my/myRoom?params=${JSON.stringify(params)}`,
          });
        }
      }
    },
    // 参加大会
    toJoinHall() {
      if (this.secondStatus == "40") {
        this.$router.push({
          name: "joinHall",
          query: {
            id: this.voteId,
          },
        });
      }
    },
    // 查看结果
    toResult() {
      // if (this.threeStatus == 2) {
      //   this.$router.push({
      //     name: "voteResult",
      //   });
      // } else
      if (this.threeStatus == 3) {
        this.$router.push({
          name: "voteResult",
          query: {
            id: this.voteId,
          },
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.voteDetail {
  box-sizing: border-box;
  width: 100%;
  background: #f6f8fb;
  font-family: PingFangSC-Regular, PingFang SC;
  padding: 20px 30px;
  padding-bottom: calc(
    40px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    40px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  .section-warpper {
    min-height: calc(100vh - 60px - constant(safe-area-inset-bottom));
    min-height: calc(100vh - 60px - env(safe-area-inset-bottom));
    background: #ffffff;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 50px 36px 0px;
    border-radius: 16px;
  }
  .section-title {
    font-size: 32px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    line-height: 44px;
    margin-bottom: 30px;
    white-space: normal;
    word-break: break-all;
    word-wrap: break-word;
  }
  .section-text {
    font-size: 28px;
    font-weight: 400;
    color: #7b4244;
    line-height: 40px;
    margin-bottom: 30px;
    white-space: normal;
    word-break: break-all;
    word-wrap: break-word;
  }
  .section-picture {
    margin-bottom: 30px;
  }
  .section-date {
    margin-bottom: 56px;
    font-size: 24px;
    font-weight: 400;
    color: #c8c8c8;
    line-height: 34px;
    padding-bottom: 56px;
    border-bottom: 2px solid #f3f3f3;
  }
  .section-main {
    .section-main--first,
    .section-main--second,
    .section-main--three {
      .main-title {
        display: flex;
        align-items: center;
        font-size: 26px;
        font-weight: bold;
        margin-bottom: 40px;
        .title-index {
          width: 34px;
          min-width: 34px;
          height: 34px;
          line-height: 34px;
          text-align: center;
          background: #fbf7f8;
          border-radius: 50%;
          color: #c99b9b;
        }
        span {
          margin-left: 24px;
          color: rgba(0, 0, 0, 0.85);
        }
      }
      .main-button {
        width: 602px;
        height: 68px;
        text-align: center;
        line-height: 68px;
        border-radius: 10px;
        font-size: 28px;
        font-weight: bold;
        margin: 0 auto 64px;
        &.default {
          background: #fbf7f8;
          color: #c99b9b;
        }
        &.verified {
          box-sizing: border-box;
          border: 2px solid #c99b9b;
          color: #c99b9b;
        }
        &.noStart {
          background: #ececec;
          color: #ababab;
        }
        &.vote {
          background: #fdebeb;
          color: #c99b9b;
        }
        &.voteClose {
          background: #dcdcdc;
          color: #ffffff;
        }
        &.voteReview {
          background: #b08f90;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
